<template>
  <a-spin :spinning="state.loading">
    <a-row type="flex" justify="start" align="middle" :gutter="[12, 12]">
      <a-col v-if="showFileUrl">
        <CFileDisplay :fileUrl="showFileUrl" :disImgProcess="true"></CFileDisplay>
      </a-col>
      <a-col>
        <a-upload
         
          :accept="accept"
          v-model:file-list="state.fileList"
          :customRequest="handleUpload"
          :showUploadList="false"
        >
          <a-button v-if="!showFileUrl"  :disabled="disabled">
            <upload-outlined></upload-outlined>
            {{ $t("common.upload") }}
          </a-button>
          <a-button v-else  :disabled="disabled">
            <upload-outlined></upload-outlined>
            {{ $t("common.change") }}
          </a-button>
        </a-upload>
      </a-col>
    </a-row>
  </a-spin>
</template>
<script>
import { reactive, watch } from "vue";
import CImage from "../../../components/CImage.vue";
import CFileDisplay from "../../../components/CFileDisplay.vue";
import { useI18n } from "vue-i18n/index";
import urlHelper from "../../../../utils/urlHelper";
import { uploadImg as uploadLetter } from "../../../../api/modules/common/index";
import { updateScheduleFile } from "../../../../api/modules/transit/outPlan/plan";

import {
  Row,
  Col,
  Upload,
  Tooltip,
  Select,
  Button,
  Form,
  message,
  Spin,
  Card,
  Popconfirm,
} from "ant-design-vue";
export default {
  name: "UploadLetter",
  components: {
    CFileDisplay,
    CImage,
    ACard: Card,
    ATooltip: Tooltip,
    AUpload: Upload,
    UploadOutlined: Upload.Outlined,
    ASpin: Spin,
    AImage: Image,
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    APopconfirm: Popconfirm,
  },
  props: {
     disabled: {
      required: false,
      default: false,
    },
    showFileUrl: {
      required: false,
      default: "",
    },
    accept: {
      //传入后缀,不传类型,用后缀做的验证
      required: false,
      default: "",
    },
    emits: ["update:showFileUrl", "handleUpdateFile"],
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const state = reactive({
      loading: false,
      fileList: [],
      showFileUrl: null,
    });

    const isVerifiedFileType = (fileName) => {
      let fileExtension = urlHelper.getUrlExtension(fileName)?.toLowerCase();
      let arrayAccept = props.accept.split(",");
      return (
        arrayAccept &&
        arrayAccept.findIndex((item) => item == fileExtension) >= 0
      );
    };

    const handleUpload = (file) => {
      if (props.accept && !isVerifiedFileType(file.file.name)) {
        message.error(
          vueI18n.t("common.the_format_of_the_uploaded_file_is_not_supported")
        );
        return;
      }

      state.loading = true;
      let parameter = new FormData();
      parameter.append("file", file.file);
      parameter.append("functionModule", 19);
      uploadLetter(parameter)
        .then(({ result }) => {
          if (result?.length > 0) {
            emit("update:showFileUrl", result[0]?.url);
            emit("handleUpdateFile");
          }
          state.loading = false;
        })
        .catch((e) => {
          state.loading = false;
        });
    };

    return {
      state,
      handleUpload,
    };
  },
};
</script>

<style lang="less" scoped></style>
