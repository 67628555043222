import { getReceivingChannelsList, getServiceLevel, getAdditionalOptions } from '@/api/modules/common/index.js'

class SupplierHelper {
  constructor(disableCache = false) {
    this.disableCache = disableCache;
    this.cache = {
      // 卡车-报价类型
      receivingChannelsList: [],
      // 卡车-服务等级
      serviceLevel: [],
      // 卡车-附加选项
      additionalOptions: [],
    };
  }

  async getQuoteTypeList() {
    try {
      if (this.cache.receivingChannelsList.length && !this.disableCache) {
        return Promise.resolve(this.cache.receivingChannelsList);
      }
      let { result } = await getReceivingChannelsList()
      if (!this.disableCache) {
        this.cache.receivingChannelsList = result ?? []
      }
      return Promise.resolve(result)
    } catch (error) {
      
    }
  }

  async getServiceLevelList() {
    try {
      if (this.cache.serviceLevel.length && !this.disableCache) {
        return Promise.resolve(this.cache.serviceLevel)
      }
      let { result } = await getServiceLevel()
      let list = []
      for (const key in result) {
        list.push({ id: key, name: result[key] })
      }
      if (!this.disableCache) {
        this.cache.serviceLevel = list
      }
      return Promise.resolve(list)
    } catch (error) {
      
    }
  }

  async getAdditionalOptionsList() {
    try {
      if (this.cache.additionalOptions.length && !this.disableCache) {
        return Promise.resolve(this.cache.additionalOptions)
      }
      let { result } = await getAdditionalOptions()
      let list = []
      for (const key in result) {
        list.push({ id: key, name: result[key] })
      }
      if (!this.disableCache) {
        this.cache.additionalOptions = list
      }
      return Promise.resolve(list)
    } catch (error) {
      
    }
  }
}

export function useSupplierHelper(disableCache = false) {
  return new SupplierHelper(disableCache);
}