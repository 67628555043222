import client from "../../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  //获得中转仓 有库存的 仓库列表
  getWarehouseList:
    "/api/WarehouseService/TransitUserOutBound/GetInvWarehouseList",
  getSeWarehouseList: "/api/WarehouseService/Warehouse/GetList",
  getHitTorrServeList: "/api/WarehouseService/PackMaterialConfig/GetList",
  create: "/api/WarehouseService/TransitUserOutBound/Create",

  getShipmentList:
    "/api/WarehouseService/TransitUserOutBound/GetShipmentPagedList",

  //查看货件的详情箱(创建计划的时候查看用.)
  getCreateShipmentDetailList: "/api/WarehouseService/TransitUserOutBound/GetCreateShipmentDetailList",
  //查看货件的详情箱(计划详情的时候查看用.)
  getShipmentDetailList: "/api/WarehouseService/TransitUserOutBound/GetShipmentDetailList",
  

  getCost: "/api/WarehouseService/TransitUserOutBound/GetOutBoundPlanFee",

  getLogisticsScheme:
    "/api/WarehouseService/WarehouseLogistics/TransitOutBoundPlanGetLogistic",

  //卡车询价
  getTruckFee: "/api/WarehouseService/TransitUserOutBound/GetTruckFee",

  cancelPlan: "/api/WarehouseService/TransitUserOutBound/Cancel",
  
  updateScheduleFile:
    "/api/WarehouseService/TransitUserOutBound/UpdateScheduleFile",
  getDetail: "/api/WarehouseService/TransitUserOutBound/GetById",
  getLogisticsAddressInfo:
    "/api/WarehouseService/TransitUserOutBound/GetLogisticesFeeDetail",
};

export const getWarehouseList = () => {
  return client.request({
    url: Api.getWarehouseList,
    data: {},
    method: "post",
  });
};

export const create = ({
  planName,
  warehouseId,
  destinationType,
  toWarehouseId,
  toAddress,
  sendoutType,
  outBoundTransitShipments,
  daycareServiceId,
  transitDeliveryMethod,
  scheduleDeliveryType,
  scheduleDeliveryTime,
  logisticesFeeDetailDto,
  payFee,
}) => {
  return client.request({
    url: Api.create,
    data: {
      planName,
      warehouseId,
      destinationType,
      toWarehouseId,
      toAddress,
      sendoutType,
      outBoundTransitShipments,
      daycareServiceId,
      transitDeliveryMethod,
      scheduleDeliveryType,
      scheduleDeliveryTime,
      logisticesFeeDetailDto,
      payFee,
    },
    method: "post",
  });
};

export const getShipmentList = ({
  warehouseId,
  name,
  seShipmentNo,
  inPlanNo,
  canScanedBoxNo,
  boxNo,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getShipmentList,
    data: {
      warehouseId,
      name,
      seShipmentNo,
      inPlanNo,
      canScanedBoxNo,
      boxNo,
      skipCount,
      maxResultCount,
      sorting,
    },
    method: "post",
  });
};

export const getShipmentDetailList = (data) => {
  return client.request({
    url: Api.getShipmentDetailList,
    data,
    method: "POST",
  });
};

export const getCreateShipmentDetailList = (data) => {
  return client.request({
    url: Api.getCreateShipmentDetailList,
    data,
    method: "POST",
  });
};

export const getSeWarehouseList = (warehouseType, isActive = true) => {
  return client.request({
    url: Api.getSeWarehouseList,
    data: { warehouseType, isActive },
    method: "post",
  });
};

export const getHitTorrServeList = (
  warehouseId,
  isPallet = true,
  isActive = true
) => {
  return client.request({
    url: Api.getHitTorrServeList,
    data: { warehouseId, isPallet, isActive },
    method: "post",
  });
};

export const getCost = ({
  transitDeliveryMethod,
  warehouseId,
  daycareServiceId,
  shipmentIds,
  logisticesFeeDetailDto,
}) => {
  return client.request({
    url: Api.getCost,
    data: {
      transitDeliveryMethod,
      warehouseId,
      daycareServiceId,
      shipmentIds,
      logisticesFeeDetailDto,
    },
    method: "post",
  });
};

export const getLogisticsScheme = ({
  warehouseId,
  destinationType,
  toWarehouseId,
  toAddress,
  outBoundTransitShipments,
  daycareServiceId,
}) => {
  return client.request({
    url: Api.getLogisticsScheme,
    data: {
      warehouseId,
      destinationType,
      toWarehouseId,
      toAddress,
      outBoundTransitShipments,
      daycareServiceId,
    },
    method: "post",
  });
};

export const updateScheduleFile = (planId, shipmentId, scheduleFileUrl) => {
  return client.request({
    url: Api.updateScheduleFile,
    data: { planId, shipmentId, scheduleFileUrl },
    method: "post",
  });
};

export const cancelPlan = (id) => {
  return client.request({
    url: Api.cancelPlan,
    data: { id },
    method: "post",
  });
};

export const getDetail = (id) => {
  return client.request({
    url: Api.getDetail,
    params: { id },
    method: "get",
  });
};

//truckApiLogistics { quoteDetailId,quoteTypeId,serviceLevel,accessorials}
export const getLogisticsAddressInfo = ({
  warehouseId,
  destinationType,
  toWarehouseId,
  toAddress,
  outBoundTransitShipments,
  daycareServiceId,
  truckApiLogistics,
  logisticsId,
  deliveryType,
}) => {
  return client.request({
    url: Api.getLogisticsAddressInfo,
    data: {
      warehouseId,
      destinationType,
      toWarehouseId,
      toAddress,
      outBoundTransitShipments,
      daycareServiceId,
      truckApiLogistics,
      logisticsId,
      deliveryType,
    },
    method: "post",
  });
};

export const getTruckFee = ({
  warehouseId,
  destinationType,
  toWarehouseId,
  toAddress,
  outBoundTransitShipments,
  daycareServiceId,
  truckApiLogistics,
  logisticsId,
}) => {
  return client.request({
    url: Api.getTruckFee,
    data: {
      warehouseId,
      destinationType,
      toWarehouseId,
      toAddress,
      outBoundTransitShipments,
      daycareServiceId,
      truckApiLogistics,
      logisticsId,
    },
    method: "post",
  });
};
